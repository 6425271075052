export function logoSlider() {
    var logoSlider = $(".js-slider-simple-c1");
        if(logoSlider.length){
        function handleSliderIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    var SliderClientLogos = new Swiper(".js-slider-simple-c1", {
                        slidesPerView: "auto",
                        // centeredSlides: true,
                        loop:true,
                        spaceBetween: 32,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                            waitForTransition: true,
                        },
                        speed: 8000
                    });

                    var SliderClientLogosRev = new Swiper(".js-slider-simple-c2", {
                        slidesPerView: "auto",
                        // centeredSlides: true,
                        loop:true,
                        spaceBetween: 32,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                            waitForTransition: true,
                        },
                        speed: 8000
                    });
                }
            });
        }
        // Create an intersection observer instance
        var observer = new IntersectionObserver(handleSliderIntersection, { threshold: 0 });
        
        // Select all buttons to observe
        
        logoSlider.each(function() {
            observer.observe(this); // Start observing each button
        });

    }
}