export function progressBarScroll() {
    const bar = document.getElementById('singleProgressBar');

    if (bar) {
        function progressBar() {
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop,
                height = document.documentElement.scrollHeight - document.documentElement.clientHeight,
                scrolled = (winScroll / height) * 100;
            bar.style.width = scrolled + "%";
        };

        window.addEventListener('scroll', () => {
            progressBar();
        })
    }
}