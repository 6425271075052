const priceCalculatorWidget = () => {
    const calculatorWidget = document.querySelector('.js-calc-widget');

    if (!calculatorWidget) return;

    const arrowIncrement = document.querySelector('.js-btn-page-increment');
    const arrowDecrement = document.querySelector('.js-btn-page-decrement');
    const inputToUpdate = document.getElementById('number-of-pages');

    arrowIncrement.addEventListener('click', () => {
        if (inputToUpdate.value === "") {
            inputToUpdate.value = 1;
        } else {
            inputToUpdate.value = parseInt(inputToUpdate.value) + 1;
        }
    })

    arrowDecrement.addEventListener('click', () => {
        if (inputToUpdate.value === "") {
            inputToUpdate.value = 1;
        } else if (parseInt(inputToUpdate.value) > 1) {
            inputToUpdate.value = parseInt(inputToUpdate.value) - 1;
        }
    })


}

export {
    priceCalculatorWidget
}