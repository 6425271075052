export default function loadMoreSolutions() {
    const tabsPanel = document.querySelectorAll('.js-load-more-content');
    const loadMoreBtns = document.querySelectorAll('.js-load-more-solutions');
    const tabsNavItems = document.querySelectorAll('.js-load-more-nav-item');
    const selectNavItems = document.querySelectorAll('.js-load-more-dropdown')

    tabsPanel.forEach((panel) => {
        let boxes = panel.querySelectorAll('.l-team__item');

        loadMoreBtns.forEach((loadMoreBtn) => {
            tabsNavItems.forEach(tabsNavItem => {
                tabsNavItem.addEventListener('click', () => {
                    boxes.forEach((box) => {
                        resetState(box, loadMoreBtn);
                    });
                });
            });

            selectNavItems.forEach(selectNavItem => {
                selectNavItem.addEventListener('change', () => {
                    boxes.forEach((box) => {
                        resetState(box, loadMoreBtn);
                    });
                });
            });

            loadMoreBtn.addEventListener('click', () => {
                boxes.forEach((box) => {
                    triggerState(box, panel, loadMoreBtn);
                });
            });
        });
    });

    // Trigger load more state and show all items
    function triggerState(item, panel, btn) {
        item.classList.toggle('-show');

        if (item.classList.contains('-show')) {
            btn.querySelector('.js-load-more-txt').innerHTML = "Hide Examples";
            btn.classList.add('expanded')
        } else {
            panel.scrollIntoView({behavior: 'instant', block: 'start'});
            btn.querySelector('.js-load-more-txt').innerHTML = btn.querySelector('.js-load-more-txt').dataset.label;
            btn.classList.remove('expanded')
        }
    }

    // Reset load more state and hide shown items
    function resetState(item, btn) {
        item.classList.remove('-show');
        btn.querySelector('.js-load-more-txt').innerHTML = btn.querySelector('.js-load-more-txt').dataset.label;
    }
}
