export function stickyPopUp() {



    const popupM = document.querySelector('.m-popup');
    if (popupM) {
        var inputMail = $('.m-popup [type="email"]');

        const otherPop = document.querySelector('.box-holder');
        inputMail.parent().prepend('<span class="c-form__ico"><svg class="icon " aria-hidden="true" role="img"><use xlink:href="#lib-icon-email"></use></svg></span>');

        const popupT = document.querySelector('.popup-toggle');

        popupM.classList.remove('off');
        popupT.classList.remove('off');

        // Function to toggle 'on' class on elements
        function toggleOnClass() {
            popupM.classList.toggle('on');
            popupT.classList.toggle('on');
            if (otherPop) {
                otherPop.classList.toggle('other')
            }
        }

        function addOnClass() {
            popupM.classList.add('on');
            popupT.classList.add('on');
            if (otherPop) {
                otherPop.classList.toggle('other')
            }
        }


        // Function to remove 'on' class from elements
        function removeOnClass() {
            popupM.classList.remove('on');
            popupT.classList.remove('on');
            if (otherPop) {
                otherPop.classList.remove('other')
            }
        }

        // Function to handle closing the popup
        function handleClose() {
            removeOnClass();
            localStorage.setItem('popupClosed', 'true'); // Save closing action in local storage
        }



        // Function to check local storage and show popup if not closed
        function checkLocalStorage() {
            if (!localStorage.getItem('popupClosed')) {
                setTimeout(addOnClass, 5000); // 5 seconds timeout
            }
        }

        // Event listener for clicking popup toggle
        popupT.addEventListener('click', toggleOnClass);

        // Event listener for clicking close button
        document.querySelector('.popup-wrap .close').addEventListener('click', handleClose);

        // Check local storage on page load
        checkLocalStorage();

        // Define the callback function for the Intersection Observer
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the 'hidden' class when the footer is in the viewport
                    popupT.classList.add('shift-up');
                    popupM.classList.add('shift-up');
                } else {
                    // Remove the 'hidden' class when the footer is out of the viewport
                    popupT.classList.remove('shift-up');
                    popupM.classList.remove('shift-up');
                }
            });
        }

        // Create a new Intersection Observer instance
        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Use the viewport as the root
            threshold: 0 // Trigger the callback as soon as any part of the footer is visible
        });

        // Target the footer element
        const footer = document.querySelector('.copy-privacy');

        // Start observing the footer
        observer.observe(footer);

    }

    // sticky RaQ CTA
    // window.addEventListener('scroll', function () {
    //     // Select the .box-cta element
    //     const boxCTA = document.querySelector('.box-cta.-site-wide');
    //
    //     // Check if the cta is there
    //     if (boxCTA) {
    //         // If the scroll position is greater than 1000px, add the 'show' class
    //         if (window.scrollY > 1000) {
    //             boxCTA.classList.add('show');
    //         } else {
    //             // otherwise
    //             // boxCTA.classList.remove('show');
    //         }
    //     }
    // });
    // Variable to track if the popup has already been triggered
    let popupTriggered = false;

    // Variable to track user interaction (scroll or click)
    let interactionDetected = false;

    // Timer variable
    let interactionTimer;

    // Function to show the popup
    function showPopup() {
        const boxCTA = document.querySelector('.box-cta.-site-wide');
        if (boxCTA) {
            boxCTA.classList.add('show');
        }
    }

    // Event listener for scroll
    window.addEventListener('scroll', function () {
        if (!interactionDetected) {
            interactionDetected = true;
            startTimer();
        }
    });

    // Event listener for click
    window.addEventListener('click', function () {
        if (!interactionDetected) {
            interactionDetected = true;
            startTimer();
        }
    });

    // Function to start the timer
    function startTimer() {
        if (!popupTriggered) {
            interactionTimer = setTimeout(() => {
                popupTriggered = true;
                showPopup();
            }, 60000); // 60 seconds
        }
    }
}