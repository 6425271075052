export function loadMoreProjects(ajax) {

        $('#load_more_projects').on( 'click', (e) => {

            e.preventDefault();
            const button = $('#load_more_projects');
            const visible = button.attr('data-visible');
            const total = button.attr('data-total');
            const loadMore = button.attr('data-load_more');
            const id = button.attr('data-id');
            const listSection = $('#project-list');
            const projectsJson = listSection.data('projects');

            // console.log(projectsJson);

            const data = {
                action: 'more_blog_projects',
                id,
                visible,
                loadMore,
                projectsJson
            };

            $.ajax({
                url: ajax.admin_url,             
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.addClass('loading');
                },
                success: function (data) {
                    if ( data ) {
                        $(listSection).append(data.output);
                        button.attr('data-visible', data.visible);
                        button.removeClass('loading');
                        if (data.visible >= total) {
                            button.hide();
                        }
                    } else {
                        button.hide();
                    }
                }
            });
            
        });

}