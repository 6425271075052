/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
export default function moreCaseStudies(ajax) {
    const $other = jQuery('.js-other-case-studies');
    let $offset = $other.data('offset');
    const $loadMore = jQuery('.js-load-more-case-studies');
    const $pagination = jQuery('.js-pagination-case-studies');
    const $perPage = $other.data('perpage');
    const $foundPosts = $other.data('found-posts');
    const $caseStudies = $other.data('case-studies');
  
    /* This should begin at posts_per_page value this is specific for this section only */
    let totalCount = 9;
  
    // animated class
    const $animatedClass = 'loadmore-animated';
  
    function get_posts(offset = 0, posts_per_page = 9, category_id = null, filter = false) {
      $loadMore.addClass('loading');
      jQuery.ajax({
        url: `${ajax.admin_url}?action=more_case_studies`,
        method: 'GET',
        dataType: 'json',
        data: {
          offset,
          posts_per_page,
          case_studies: $caseStudies,
          ajax: true,
        },
        success(response) {
          jQuery('.js-message-case-studies').html(null);
  
          if (response !== false) {
            // increment total count
            totalCount += response.count;
  
            if (filter) { $other.html(null); }
            $other.append(response.output).animate({ opacity: 1 }, 200, () => {
              jQuery(`.${$animatedClass}`).animate({ opacity: 1 }, 200);
              jQuery(`.${$animatedClass}`).each((i, obj) => {
                jQuery(obj).removeClass(`.${$animatedClass}`);
  
                const $moreData = jQuery('.jsCaseStudiesLoadedMore');
                const $mainCont = jQuery('.jsMainCont');
  
                $mainCont.append($moreData.html());
                $moreData.remove();
              });
            });
            $loadMore.removeClass('loading');
            if (response.count === 0
                || response.count < posts_per_page
                ) {
              $loadMore.hide();
              $pagination.hide();
              $other.removeClass('hidden');
            }
          }
  
          if (response === false || response.output === false) {
            $loadMore.hide();
            $pagination.hide();
            $other.removeClass('hidden');
            // jQuery('.js-message-case-studies').html('No More Posts');
          }
        },
      });
    }
  
    $loadMore.on('click', (e) => {
      e.preventDefault();
  
      $offset += $other.data('perpage');
      get_posts($offset, $perPage);
    });
  }
  