/**
 * Toggle search icon with accessibility in mind
 * @param {string} el - selector for adding an active class
 */

export function toggleSearch(el) {
  const btn = document.querySelector(el);
  const searchForm = document.querySelector('.jsSearchForm');
  const searchInput = document.querySelector('.jsSearchProd');
  const searchBtn = document.querySelector('.jsSearchBtn');
  const $search_wrapper = jQuery('.jsAutoSearchParent');


  if (btn) {
    btn.addEventListener('click', (event) => {
      event.preventDefault();
      if (btn.getAttribute('aria-expanded') === 'false') {
        openSearch();
      } else {
        closeSearch();
      }
    });

    if (searchInput) {
      function logFocus(event) {
        searchInput.value = '';
      }
      searchInput.addEventListener('focus', logFocus);
    }

  }
  /* Check outside click */
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.c-site-search')) {
      // click outside
      if (btn) {
        if (btn.getAttribute('aria-expanded') === 'true') {
          closeSearch();
        }
      }
    } else {
      // click inside
    }
  }, false);

  function closeSearch() {
    searchForm.classList.remove('is-active');
    btn.setAttribute('aria-expanded', 'false');
    $search_wrapper.removeClass('active');
    searchInput.setAttribute('tabindex', '-1');
    searchBtn.setAttribute('tabindex', '-1');
  }

  function openSearch() {
    searchForm.classList.add('is-active');
    btn.setAttribute('aria-expanded', 'true');
    searchInput.removeAttribute('tabindex');
    searchInput.value = '';
    searchBtn.removeAttribute('tabindex');
    // set focus
    jQuery('.jsSearchProd').focus();
  }
}
