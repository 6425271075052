// import ScrollOut from "scroll-out";
// // import Splitting from "splitting";

export function scrollAnimations() {

    // Function to handle the intersection events
    function handleIntersect(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.dataset.scroll = 'in';
                if (entry.target.dataset.once === 'true') {
                    observer.unobserve(entry.target);
                }
            } else {
                entry.target.dataset.scroll = 'out';
            }
        });
    }

    // Helper function to create an observer with specific options
    function createObserver(options, selector, once) {
        const observer = new IntersectionObserver(handleIntersect, options);
        document.querySelectorAll(selector).forEach(element => {
            element.dataset.once = once.toString();
            observer.observe(element);
        });
    }

    // Options for different targets
    const options1 = {
        threshold: 0.1,
        root: null, // Use the viewport as the root
    };

    const options2 = {
        rootMargin: '0px 0px -600px 0px', // Trigger 600px before element is in view
    };

    const options3 = {
        threshold: 0,
        root: null,
    };

    // Observers for different targets
    createObserver(options1, ".in-view, .a-fade-trans, .s-text-box, .s-blog-section, .l-sign-up, .form-section__inner, .s-analysis, .s-services-custom, .services .l-dcbl, .s-team .bg-text", true);
    createObserver(options2, ".in-cs", true);
    createObserver(options3, ".in-view-r", false);

}
