export function footAccordion() {
   
    
    
    if ($(window).width() < 575) {
        
        $(".temp-accordion .widgettitle").each(function () {
            $(this).on('touchstart',function () {
                if ( $(this).hasClass('active')) {
                    $(this).removeClass('active').next().removeClass('active')
                }else{
                    $(".widgettitle").removeClass('active').next().removeClass('active');
                    $(this).addClass('active').next().addClass('active');
                }
            })
        })
     }
     else {
        $(".widgettitle").removeClass('active').next().removeClass('active');
     }
}
