export default function caseStudyTabs() {
    const $caseTabs = jQuery(".js-case-study-tabs");

    if($caseTabs.length > 0) {
        $caseTabs.each(function(i, obj) {
            const $this = jQuery(this);

            // tabs panel click
            const $tabsPanel = $this.find(".js-tabs-nav-item");

            $tabsPanel.on('click', function(e) {
                // e.stopPropagation();
                const $tabItem = jQuery(this);
                const tabItemIndex = $tabItem.attr('data-img-index');
                const $tab = $tabItem.parents(".js-tab");
                if($tab.find(`.js-img-tab[data-tab-index='${tabItemIndex}']`).length > 0) {
                    const $imgSrc = $tab.find(`.js-img-tab[data-tab-index='${tabItemIndex}']`).attr('data-src');

                    $this.find('.l-tbnav-v1__img').animate({ opacity: 0 }, 200, function() {
                        $this.find('.js-left-img').attr("src", $imgSrc);
                        $this.find('.js-left-img').attr("data-src", $imgSrc);
                        $this.find('.l-tbnav-v1__img').animate({ opacity: 1 }, 200);
                    });
                }
            });
        });
    }

}
