export function scroller24 (){
    if(document.querySelector(".our-process24") != null){
        const scroller = document.querySelector('.scroller');
        const innerScroller = document.querySelector('.scroller__inner');
        const slides = document.querySelectorAll('.scroll-slide');
        const slideWidth = slides[0].offsetWidth; // Assuming all slides have the same width
        let scrollPosition = 0;
        let maxScroll = innerScroller.scrollWidth - scroller.clientWidth;
        let lastScrollPosition = 0; // To track the last scroll position
        const progressBar = document.querySelector('.progress');

        // Function to detect if the device is touch-capable
        function isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }

        function scrollToMiddle(slideIndex) {
        const scrollerCenter = ((scroller.clientWidth / 10) * 3.5);
        const slideCenter = slideIndex * slideWidth + slideWidth / 2;
        const newScrollPosition = slideCenter - scrollerCenter;

        // Clamp new scroll position to prevent overscrolling
        scrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));
        innerScroller.style.transform = `translateX(-${scrollPosition}px)`;
        
        if(slideIndex < 1){
            progressBar.style.right  = `${slideWidth * 1.5}px`;
        }else{
            progressBar.style.right  = `${(scrollPosition - (slideWidth / 1.2)) * -1}px`;
        }
        console.log(slideIndex);
        // Add active class to clicked slide and all previous slides
        slides.forEach((slide, index) => {
            if (index <= slideIndex) {
            slide.classList.add('active');
            } else {
            slide.classList.remove('active');
            }
        });


        lastScrollPosition = scrollPosition;
        }

        if (!isTouchDevice()) {
        // Mouse wheel event for horizontal scrolling with transform
        scroller.addEventListener('wheel', (e) => {
            // Calculate new scroll position based on deltaY
            const scrollAmount = e.deltaY;
            const direction = scrollAmount > 0 ? 1 : -1; // 1 for down (left scroll), -1 for up (right scroll)
            const remainingScroll = maxScroll - scrollPosition;
            // Calculate new scroll position
            scrollPosition += direction * 70; // Adjust multiplier for scroll speed

            // Clamp scroll position to prevent overscrolling
            scrollPosition = Math.max(0, Math.min(scrollPosition, maxScroll));

            // Update transform style with the correct scroll position
            innerScroller.style.transform = `translateX(-${scrollPosition}px)`;
            

            // Prevent default wheel action if we're scrolling horizontally
            if (scrollPosition !== 0 && scrollPosition !== maxScroll) {
            e.preventDefault();
            }

            // Allow normal vertical scrolling if at the start or end
            if (scrollPosition === 0 || scrollPosition === maxScroll) {
            // scroller.style.overflowY = 'auto';
            } else {
            // scroller.style.overflowY = 'hidden';
            }

            // Check if a slide is in the middle and update active class based on direction
            const scrollerCenter = ((scroller.clientWidth / 10) * 3.5);
            slides.forEach((slide, index) => {
            const slideStart = index * slideWidth;
            const slideEnd = slideStart + slideWidth;
            const slideCenter = (slideStart + slideEnd) / 2;

            if (scrollAmount > 0 && scrollPosition + scrollerCenter >= slideStart && lastScrollPosition + scrollerCenter < slideStart) {
                // Scrolling down (to the left) and slide just passed the center
                slide.classList.add('active');
            } else if (scrollAmount < 0 && scrollPosition + scrollerCenter <= slideStart && lastScrollPosition + scrollerCenter > slideStart) {
                // Scrolling up (to the right) and slide just passed the center
                slide.classList.remove('active');
            }
            if(index < 1){
                progressBar.style.right  = `${slideWidth * 1.5}px`;
            }else{
                progressBar.style.right  = `${(scrollPosition - (slideWidth / 1.2)) * -1}px`;
            };
            });

            // Update last scroll position
            lastScrollPosition = scrollPosition;
        });

        // Add click event listener to each slide to scroll it into the middle
        slides.forEach((slide, index) => {
            slide.addEventListener('click', () => {
            scrollToMiddle(index);
            });
        });
        }else {
        // Intersection Observer for touch devices
        const observerOptions = {
            root: scroller,
            threshold: 0.5 // Adjust this value as needed
        };

        const observerCallback = (entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            } else {
                entry.target.classList.remove('active');
            }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        slides.forEach(slide => {
            observer.observe(slide);
        });
        
        function updateProgressBar() {
            const progress = (scroller.scrollLeft / maxScroll) * 1200;
            progressBar.style.width = `${progress}px`;
        }
            // Listen for the scroll event to update the progress bar
            scroller.addEventListener('scroll', updateProgressBar);

            // Initialize the progress bar on load
            updateProgressBar();
        }
    }
}