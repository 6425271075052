function calculate() {
  /**
   * Getting all necessary stuff.
   */
  const calc = document.querySelector('.js-conv-calculator');
  const results = document.querySelector('.js-conv-results');
  const conv = document.querySelector('#js-conv-field');
  const visits = document.querySelector('#js-visits-field');
  const answer = document.querySelector('.js-answer');
  const good = document.querySelector('.js-good');
  const bad = document.querySelector('.js-bad');
  const calcAgain = document.querySelector('.js-calc-again');

  /**
   * Calculate the final result.
   * @type {number}
   */
  let result = Number(((conv.value / visits.value) * 100).toFixed(2));

  /**
   * Hiding calculator, showing results and populating results div.
   */
  calc.classList.add('hidden');
  results.classList.remove('hidden');
  answer.innerHTML = `${result}%`;

  if (Number(conv.value) && Number(visits.value)) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'conversion_calculator_form',
      'conversions': conv.value,
      'visits': visits.value,
    });
  }

  /**
   * Determine which result to show good or bad.
   */
  switch (true) {
    case result > 7:
      good.classList.remove('hidden');
      break;
    case result < 7:
      bad.classList.remove('hidden');
      break;
    case result === 7:
      good.classList.remove('hidden');
      bad.classList.remove('hidden');
      break;
    default:
      break;
  }

  /**
   * Calculate again function resetting inputs and hiding results.
   */
  if (calcAgain) {
    calcAgain.addEventListener('click', () => {
      calc.classList.remove('hidden');
      results.classList.add('hidden');
      good.classList.add('hidden');
      bad.classList.add('hidden');
      conv.value = '';
      visits.value = '';
      result = 0;
    });
  }
}

/**
 * Main function included in index.js.
 */
export default function conversionsCalculator() {
  /**
   * Calling calculate on click on form submit
   */
  const form = document.getElementById('conv-calc');

  if (form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();

      calculate();
    });
  }

}
