export function menuHover(){
      // Select all menu items at the first level
      const menuItems = document.querySelectorAll('.nav-main__links > li  > a > .menu-item-title');

      // Iterate through each menu item
      menuItems.forEach(item => {
        // Get the text content of the menu item
        const text = item.textContent;

        // Create a new string with each letter (including spaces) wrapped in a span
        let newContent = '';
        for (let i = 0; i < text.length; i++) {
            if (text[i] === ' ') {
                newContent += `<span class="space">${text[i]}</span>`;
            } else {
                newContent += `<span>${text[i]}</span>`;
            }
        }

        // Set the new content to the original item
        item.innerHTML = newContent;

        // Clone the item and add the 'double' class
        const clonedItem = item.cloneNode(true);
        clonedItem.classList.add('double');

        // Insert the cloned item after the original item
        item.parentNode.insertBefore(clonedItem, item.nextSibling);
      });
} 