/**
 *  Swiper slider with thumbs for testimonials module
 */

const vSlider = () => {

    const vSliderEl = document.querySelector('.js-slider-vertical');

    if(vSliderEl) {

        let vSliderElCount = document.querySelectorAll(".js-slider-vertical .gallery-top .swiper-slide").length;

        const sliderTop = new Swiper(".gallery-top", {
            spaceBetween: 0,
            effect: "slide",
            direction: "vertical",
            centeredSlides: true,
            initialSlide: 1,
            breakpoints: {
                300: {
                    slidesPerView: 1
                },
                1100: {
                    slidesPerView: 1
                },
                1650: {
                    slidesPerView: 1
                }
            },
            // autoplay: {
            //     delay: 2000,
            //     reverseDirection: true,
            //     pauseOnMouseEnter: true,
            // },
            speed: 1000,
            loop: true,
            loopedSlides: vSliderElCount,
            loopAdditionalSlides: 2,
            slideToClickedSlide: true
        });

        const sliderBottom = new Swiper(".gallery-bottom", {
            initialSlide: 1,
            centeredSlides: true,
            direction: "vertical",
            loop: true,
            loopedSlides: vSliderElCount,
            loopAdditionalSlides: 2,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        });

        sliderTop.controller.control = sliderBottom;
        sliderBottom.controller.control = sliderTop;

// sliderTop.on("slideChange", function () {
//   sliderBottom.slideTo(sliderTop.activeIndex);
// });

// sliderBottom.on("slideChange", function () {
//   sliderTop.slideTo(sliderBottom.activeIndex);
// });


    }

}


export {
    vSlider
}
