/**
 * Advanced slider type
 */

import SwiperWithTabs from "../../library/sliders/swiper-with-tabs";
import { isAutoPlayOn } from "../../library/sliders/slider-options/autoplay";
import { isLazyLoadOn } from "../../library/sliders/slider-options/lazy";
import { isBreakpointsOn } from "../../library/sliders/slider-options/breakpoints";
import {isNavigationOn} from "../../library/sliders/slider-options/navigation";
import {u_parseBool} from "../../utils/u_types";
import {isLoopOn} from "../../library/sliders/slider-options/loop";
import { isSlideToClicedSlideOn } from "../../library/sliders/slider-options/slide-to-clicked-slide";


// config selectors only here
const advancedName = 'js-slider-advanced';
const advSliderSel = '.js-slider-advanced';
const advSliderTabs = '.l-slider-nav';

// find those selectors
const advSliderList = document.querySelectorAll(advSliderSel);

const advancedSliders = () => {
    // loop through sliders and add ID's to it

    let advSliderOptions = [];
    let advSliders = [];
    let sliderTabOptions = [];
    let advSliderNav = [];
    let sliderNav;
    let advSliderThumbs = [];
    let sliderThumbOptions = [];

    let beforeAfterThumbSlider = null

    advSliderList.forEach( (slider, i) => {
        advSliderOptions[i] = {
            centeredSlides: true,
            observer: true, observeParents: true,
            calculateHeight:true,

            pagination: {
                el: '.m-slider__pagination',
                clickable: true
            },
        };
        sliderTabOptions[i] = {
            item: '.js-nav__item',
            active: 'is-active',
            trigger: 'click',
        }

        if(slider.classList.contains('js-before-after')) {
            sliderThumbOptions[i] = {
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true,
                loop: true,
                slideToClickedSlide: true,
                observer: true, observeParents: true
            }
        } else {
            sliderThumbOptions[i] = {
                spaceBetween: 10,
                slidesPerView: 'auto',
                freeMode: true,
                threshold: 10,
                watchSlidesProgress: true,
                wrapperClass: 'c-slider-nav',
                observer: true, observeParents: true,
            }
        }


        let isThumbs = u_parseBool(slider.getAttribute('data-slider-thumbs'));

        let sliderID = `${advancedName}-${i}`;
        slider.setAttribute('id', sliderID);

        let sliderParent = slider.closest('.m-slider');

        if(sliderParent) {
            sliderNav = sliderParent.querySelector(advSliderTabs);
        }

        let sliderThumbsSelector;
        if(sliderNav) { 
            if(isThumbs) {
                let sliderThumbsID = 'js-slider-advanced-thumbs-' + i;
                sliderNav.setAttribute('id', sliderThumbsID);
                sliderThumbsSelector = '#' + sliderThumbsID;

            }
            else {
                let sliderTabID = 'js-slider-advanced-nav-' + i;
                sliderNav.setAttribute('id', sliderTabID);
                sliderTabOptions[i].element = '#' + sliderTabID;
            }
        }

        advSliderOptions[i] = isLoopOn(slider, advSliderOptions[i]);
        advSliderOptions[i] = isAutoPlayOn(slider, advSliderOptions[i]);
        advSliderOptions[i] = isLazyLoadOn(slider, advSliderOptions[i]);
        advSliderOptions[i] = isBreakpointsOn(slider, advSliderOptions[i]);
        advSliderOptions[i] = isSlideToClicedSlideOn(slider, advSliderOptions[i]);

        // .m-slider parent is hardcoded in isNavigationOn options
        advSliderOptions[i] = isNavigationOn(slider, advSliderOptions[i], advancedName, i);

        if(isThumbs) {

            advSliderThumbs[i] = new Swiper(sliderThumbsSelector, sliderThumbOptions[i]);

            advSliderOptions[i].thumbs = {};
            advSliderOptions[i].thumbs.swiper = advSliderThumbs[i];

            advSliderOptions[i].noSwipingSelector = '.l-slider-nav, .m-slider__pagination';

            /*
            if(slider.classList.contains('js-before-after')) {

                jQuery('.js-before-after-thumb').on('click', function(e) {
                    e.stopPropagation();
                    const allSlides = [...document.querySelectorAll('.js-before-after-thumb')];
                    const current = jQuery(this).get(0);

                    advSliderThumbs[i].slideTo(allSlides.indexOf(current));

                });

                beforeAfterThumbSlider = advSliderThumbs[i];

            }
            */

        }

        advSliders[i] = new Swiper(slider, advSliderOptions[i]);

        /*if(slider.classList.contains('js-before-after')) {
            advSliders[i].on('slideChange', function (swp) {
                if(beforeAfterThumbSlider) {
                    beforeAfterThumbSlider.slideTo(swp.realIndex);
                }
            });
        }*/   



        if(sliderNav) {
            if(advSliders[i].initialized) {
                advSliderNav[i] = new SwiperWithTabs(advSliders[i], sliderTabOptions[i]);
                const containerElement = advSliders[i].el;
                const underline = containerElement.querySelector('.js-slider-nav-underline');

                if(underline) {
                    advSliderNav[i].swiper.on('slideChange', function () {
                        underline.style.transform = 'translate3d(' + advSliderNav[i].swiper.activeIndex * 100 + '%,0,0)';
                    });
                }
            }
        }

    })

}

export {
    advancedSliders
}
