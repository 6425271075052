export function expandBAImage() {
    $(".bas-expander .c-qz-btn").each(function () {
        $(this).click(function () {
            if ($(this).hasClass("on")) {
                $(this).parent().prev().css('min-height', '');
                $(this).removeClass("on");
            } else {
                var height = $(this).parent().prev().find(".c-image__media").outerHeight();
                $(this).parent().prev().css('min-height', height);
                $(this).addClass("on");
            }
        })
    });
}