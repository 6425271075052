export function tabsTabDrop() {

    const tabItems = document.querySelectorAll('.js-tabs-nav-item');
    tabItems.forEach(item => {
        item.addEventListener('keydown', function (event) {
            // Check if the Enter key is pressed
            if (event.keyCode === 13) {
                item.click();
            }
        });
    });

    const gridderListItems = document.querySelectorAll('.gridder-list.l-team__item');
    gridderListItems.forEach(item => {
        item.addEventListener('keydown', function (event) {
            // Check if the Enter key is pressed
            if (event.keyCode === 13) {
                item.click();
            }
        });
    });

}
