export function footerHover() {

    $(".footer-nav > .menu-item").each(function () {

        if ($(".footer-nav > .menu-item").hasClass("current-menu-parent")) {

            $(".footer-nav > .current-menu-parent").addClass('is-hover');

            $(".footer-nav").mouseleave(function () {
                $(".footer-nav > .current-menu-parent").addClass('is-hover');
            });

        } else {

            $(".footer-nav > .menu-item:first-child").addClass('is-hover');

            $(".footer-nav").mouseleave(function () {
                $(".footer-nav > .menu-item:first-child").addClass('is-hover');
            });
        }

        $(this).mouseenter(function () {
            $(this).addClass('is-hover');
        });

        $(this).mouseleave(function () {
            $(".footer-nav > .menu-item").removeClass('is-hover')
        });
    });

}
