/**
 * Toggle mobile nav
 * @param {string} el - selector for adding an active class
 */

export function baCollapser(el, elTarget) {

    const btn = document.querySelector(el);
    const btnTxt = document.querySelector(el);

    if (btn) {

        const btnTarget = document.querySelector(elTarget);
        const btnTarget2 = btnTarget.querySelector('.ba-nav');

        let currentSlide = btnTarget.querySelectorAll('.ba-slider .swiper-slide');
        
        currentSlide.forEach(function (item, index){
            let currentExpander = item.querySelector('.l-testimonials ');
            let height = item.querySelector('img').offsetHeight;

            currentExpander.style.maxHeight = `${height}px`;
        });

        btn.addEventListener('click', event => {
            event.preventDefault();
            let currentSlide = btnTarget.querySelector('.ba-slider .swiper-slide');
            let height = currentSlide.querySelector('img').offsetHeight;
            let slider = btnTarget.querySelector('.ba-slider .swiper-wrapper');
            if (btnTarget.classList.contains('ba-active')) {
                btnTarget.classList.remove('ba-active');
                btnTarget2.scrollIntoView({  block: "start" });
                setTimeout(() => {
                    slider.style.height = "auto";
                }, 1200);
                
            } else {
                btnTarget.classList.add('ba-active');
                slider.style.height = `${height}px`;
            }
        })

    }
}
