export function videoHover() {

    window.onload = function () {
        const vids = document.getElementsByClassName('services-media'); // Loop over the selected elements and add event listeners
        for (let i = 0; i < vids.length; i++) {
            let vidsItem = vids[i].querySelector('video');
            vids[i].addEventListener('mouseover', function (e) {
                vidsItem.play()
            });
            vids[i].addEventListener('mouseout', function (e) {
                vidsItem.pause()
            })
        }
    }

}
