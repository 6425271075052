/**
 * Toggle mobile nav
 * @param {string} el - selector for adding an active class
 */

export function mobileNav(el, subEl) {
    const btns = document.querySelectorAll(el);
    const body = document.querySelector('body');
    const subMenus = document.querySelectorAll('.js-dropdown-menu-holder');
    let newWidth = window.innerWidth;
    // main menu toggle
    btns.forEach(btn => {
        btn.addEventListener('click', event => {

            event.preventDefault();

            if (newWidth < 1025) {
                subMenus.forEach(subMenu => {
                    subMenu.querySelector('.sub-menu').style.display = "block";
                });
            }

            if (btn.getAttribute('aria-expanded') === 'false') {
                btn.classList.add('is-active');
                body.classList.add('nav-active');
                btn.setAttribute('aria-expanded', 'true');
                shiftFocus('#menu-header a');
                keepFocusInsideDiv();
                closeMenuEsc();
            } else {
                btn.classList.remove('is-active');
                body.classList.remove('nav-active');
                btn.setAttribute('aria-expanded', 'false');
                shiftFocus('.js-toggleNav');
            }
        });
    });


    // submenu toggle

    const menuItems = document.querySelectorAll('.js-menu-dropdown-toggle');

    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            // Close all other menus
            menuItems.forEach(otherItem => {
                if (otherItem !== item) {
                    const otherMenu = otherItem.querySelector('.js-dropdown-menu-holder');
                    if (otherMenu) {
                        otherMenu.style.height = '0';
                        otherMenu.classList.remove('nav-active');
                        otherItem.classList.remove('nav-active');
                    }
                }
            });

            // Toggle the clicked menu
            const menu = item.querySelector('.js-dropdown-menu-holder');
            if (menu) {
                const subMenu = menu.querySelector('.sub-menu');
                const height = subMenu ? subMenu.offsetHeight : 0;

                // Toggle height and active class
                const isActive = menu.classList.contains('nav-active');
                menu.style.height = isActive ? '0' : `${height}px`;
                menu.classList.toggle('nav-active');
                item.classList.toggle('nav-active');
            }
        });
    });

}


function shiftFocus(selector) {
    const element = document.querySelector(selector);

    if (element) {
        element.focus();
    }
}

function keepFocusInsideDiv() {
    document.addEventListener('keydown', function (e) {
        let focusableElements = document.querySelectorAll('.nav-main__wrap a, .nav-main__wrap button, .nav-main__wrap input, .nav-main__wrap select, .nav-main__wrap textarea, .nav-main__wrap [tabindex]:not([tabindex="-1"])');
        focusableElements = Array.prototype.slice.call(focusableElements);

        let firstElement = focusableElements[0];
        let lastElement = focusableElements[focusableElements.length - 1];

        if (e.key === 'Tab') {
            if (e.shiftKey) /* Shift + Tab */ {
                if (document.activeElement === firstElement) {
                    e.preventDefault();
                    lastElement.focus();
                }
            } else /* Tab */ {
                if (document.activeElement === lastElement) {
                    e.preventDefault();
                    firstElement.focus();
                }
            }
        }
    });
}

function closeMenuEsc() {
    document.addEventListener('keydown', function (event) {
        if (event.key === "Escape" || event.key === "Esc") {
            const body = document.body;
            if (body.classList.contains('nav-active')) {
                body.classList.remove('nav-active');
                document.querySelector('.js-toggleNav').classList.remove('is-active');
                document.querySelector('.js-toggleNav').setAttribute('aria-expanded', 'false');
                shiftFocus('.js-toggleNav');
            }
        }
    });
}