/* eslint-disable linebreak-style */
/* eslint-disable no-new */
/* eslint-disable no-undef */

/*
 * @title Main App
 * @description Application entry point
 */

// Header
import { stickyHeader } from './header/sticky-header';
// import { searchOverlay } from './header/search-overlay';
import { mobileNav } from './header/mobile-nav';
import { toggleSubmenu } from './header/toggle-submenu';

// function calls
import { callSliders } from './function-calls/sliders';
import { callAccordions } from './function-calls/accordions';
import { callTabAccordionsMobile } from './function-calls/tabs-to-accordion-mobile';

// components
import heroPlayer from './components/hero-player';
import hide_box_cta_widget from './components/box-cta-widget-cookie';
// import openCaseStudy from './components/open-case-study';
// import whitepaperPopup from './components/whitepaper-form';
import readMoreX from './components/read-more';
import readMore from './function-calls/tinymce-read-more/read-more';
import { loadMoreProjects } from './components/load-more-projects';
import { loadMorePosts } from "./load-more/load-more-posts";
import moreCaseStudies from './components/case-studies';
import { toggleSearch } from './components/site-search';
// import PopupsForms from './components/popups-form';
import { backToTop } from './components/back-to-top';
import { progressBarScroll } from './components/progress-bar';
import { toggleElement } from './components/toggle-element';
import { tabsUnderline } from './components/tabs-underline';
import caseStudyTabs from './components/case-study-tabs';
import { baCollapser } from './components/ba-collapser';
import { expandImage } from './components/expand-image';
import { footerHover } from './components/footer-hover';
// import { raqPopup } from './components/popup-raq';
import { autoTabs } from './components/auto-tabs';
import { locationAccordion } from './components/location-accordion';
import { gForms } from './components/g-forms';
import { footAccordion } from './components/foot-accordion';
// import { navJumper } from './components/nav-jumper';
import { expandBAImage } from './components/ba-single';
import { fakeFooterMenu } from './components/fake-menu';
import loadMoreSolutions from './components/load-more-solutions';
import conversionsCalculator from './components/conv-calc';
import { priceCalculatorWidget } from './components/calculator-widget.js';
import { priceCalculator } from './components/calculator';


import { logoSlider } from './components/logo-slider';

import { stickyPopUp } from './components/sticky-whitepaper';

import { pauseHeroAnimations } from './components/pause-hero-anim';

import { menuHover } from './components/menu-hover-effect';

import { scroller24 } from './components/scroller';

// import { tabsToSlider } from './components/tabs-to-slider';
// utils
// import { heroHomeScroll } from "./library/animations/hero-home-scroll";

// eslint-disable-next-line camelcase
import { u_addTouchToHtml } from './utils/u_is-touch-device';

// libraries
import TabAccordion from './library/tabs-accordions/tab-accordion';

import DSMPTab from './library/tabs-accordions/tab-single';
import DSMPTabsTab from './library/tabs-accordions/DSMPTabs-tab';
import DSMPTabsDropdown from './library/tabs-accordions/DSMPTabs-dropdown';
import DSMPTabsTabDropdown from './library/tabs-accordions/DSMPTabs-tabdropdown';

import DSMPMediaControls from './library/media-controls/media-control';

import PureCounter from './library/counters/purecounter';
import gridderInit from './library/collapsers/gridder-init';

// Function calls
import { boxCtaPosition } from './function-calls/box-cta-position';

// Effects
import { scrollAnimations } from "./visuals/scroll-animations";
import { videoHover } from "./visuals/video-hover";

// eslint-disable-next-line import/prefer-default-export
export function funk() {
  return dsAjax;
}

document.addEventListener('DOMContentLoaded', () => {
  // check whether it is touch device or not
  u_addTouchToHtml();

  //Reposition box cta if the is TOC
  boxCtaPosition();

  // Header
  stickyHeader('.site-header', 'is-sticky');
  // searchOverlay();
  mobileNav('.js-toggleNav', '.js-menu-dropdown-toggle');
  if (window.matchMedia('(max-width: 1024px)').matches) {
    toggleSubmenu();
  }

  new PureCounter({
    selector: '.js-counter-number',
  });

  new TabAccordion();

  gridderInit();

  new DSMPMediaControls();

  callSliders();
  callAccordions();
  // callAccordionsLight();

  loadMorePosts();

  // components
  heroPlayer('.videoPlayer');
  // openCaseStudy();
  // whitepaperPopup();
  readMoreX();
  readMore();
  loadMoreProjects(dsAjax);
  moreCaseStudies(dsAjax);
  toggleSearch('.js-searchToggle');
  // PopupsForms();
  backToTop('backToTop');
  progressBarScroll();
  toggleElement('js-toggleElement', 'js-toggleTrigger');
  tabsUnderline();
  caseStudyTabs();
  baCollapser('.js-view-more', '.s-before-after');
  expandImage();
  // raqPopup();
  autoTabs();
  locationAccordion();

  gForms();
  footAccordion();
  // navJumper();

  fakeFooterMenu();
  loadMoreSolutions();
  conversionsCalculator();

  expandBAImage();

  priceCalculatorWidget();
  priceCalculator();

  stickyPopUp();

  pauseHeroAnimations();

  menuHover();

  if ($("body").hasClass("home")) {
    // heroHomeScroll();
  }

  logoSlider();

  new DSMPTab();
  new DSMPTabsTab();
  new DSMPTabsDropdown();
  new DSMPTabsTabDropdown();

  callTabAccordionsMobile();
  scrollAnimations();


  scroller24();





  // Initialize the class on DOM content load
  new hide_box_cta_widget();


  if (window.matchMedia("(min-width: 1025px)").matches) {

    videoHover();
    footerHover();
  }

});
$(window).on("resize", function () {
  footAccordion();
});
// Vendors libs

const myLazyLoad = new LazyLoad({
  elements_selector: '.lazy',
});

window.myLazyLoad = myLazyLoad;

/** Fix jQuery passive listeners */
jQuery.event.special.touchstart = {
  setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.touchmove = {
  setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
  },
};
jQuery.event.special.wheel = {
  setup(_, ns, handle) {
    this.addEventListener('wheel', handle, { passive: true });
  },
};
jQuery.event.special.mousewheel = {
  setup(_, ns, handle) {
    this.addEventListener('mousewheel', handle, { passive: true });
  },
};

// Accessibility Patches
import { tabsTabDrop } from './patches/tabsTabDrop.js';
tabsTabDrop();