export function loadMorePosts() {

    (function ($) {
        function done(){
            var newPosts = $(".l-latest-cat-item.loaded:not(done)");
            function addDelayedClass(selector, className, delay) {
                // Get all the elements matching the selector
                var elements = $(selector);
                
                // Loop through each element and add the class with a delay
                elements.each(function(index) {
                  var element = $(this);
                  setTimeout(function() {
                    element.addClass(className);
                  }, delay * index);
                });
              }
            addDelayedClass(newPosts, "done", 110);
        }
        
        $('#load_more_posts').on( 'click', (e) => {

            e.preventDefault();
            const button = $('#load_more_posts');
            const paged = button.attr('data-paged');
            const total = button.attr('data-total');
            const author = button.attr('data-author');
            const listSection = $('#posts_wrap');
            const buttonWrapper = $('.latest-cat.lower .more-wrap');

            const data = {
                action: 'more_blog_posts',
                nonce: ds.nonce,
                currentPage: paged,
                author,
            };

            $.ajax({
                url: ds.ajax_url,             
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                    button.addClass('loading');
                    
                },                
                success: function (data) {

                    if ( data ) {
                        
                        $(listSection).append(data.posts);
                        setTimeout(done(), 110);

                        button.attr('data-paged', data.paged);
                        button.removeClass('loading');
                        
                        if (data.paged == total) {
                            button.hide();
                        }
                        
                    } else {
                        button.hide();
                    }
                }
            });
            
        });

    }(jQuery));

}