
export function tabsUnderline() {
    const tabNavs = document.querySelectorAll('.js-tbnav');

    if(window.innerWidth > 1024) {
        if(tabNavs.length > 0) {
            tabNavs.forEach((tabNav) => {
                let tabs = tabNav.querySelectorAll('.js-tabs-nav-item');
                let tabsUndeline = tabNav.querySelector('.js-tabs-nav-underline');

                tabs.forEach((item, i) => {
                    item.addEventListener('click', () => {
                        moveTabsUnderline(tabsUndeline, i);
                    })
                })
            })
        }

        function moveTabsUnderline(elem, i) {
            if(elem && i >= 0) {
                elem.style.transform = 'translate3d(' + i * 100 + '%,0,0)';
            }
        }
    }
}
