export function expandImage() {

    $(".ba-expander .c-qz-btn").each(function () {
        $(this).click(function () {
            triggerExpandImage($(this));
        })
    });

    const currentUrlHash = $(location).prop('hash').substr(1);
    const tabs = $('.ba-single .js-tabs-panel');

    tabs.each(function () {
        const tab = $(this);
        const projects = tab.find('.ba-single__project');
        const tabsID = tab.attr('id');
        const tabNavItemControls = $('.ba-single .js-tabs-nav-item[aria-controls='+ tabsID + ']');

        if (currentUrlHash) {
            closeTabs(tab, tabNavItemControls);
        }

        projects.each(function () {
            let projectHash = $(this).find('.ba-single__project-hash').attr('name');

            if (currentUrlHash == projectHash) {
                $(this).closest('.lm-col').addClass('on');
                $(this).closest('.lm-col').prevAll().addClass('on');
                if(tabsID == $(this).find('.c-qz-btn').closest('.js-tabs-panel').attr('id')) {
                    openTab(tab, tabNavItemControls);

                    setTimeout(() => {
                        $(this).find('.c-qz-btn').click();
                    }, 1500);
                    setTimeout(() => {
                        $(this).find(".c-image").css('max-height', 'none')
                    }, 2100);
                }
            }
        });
    });

    function closeTabs(tab, tabNavItemControls) {
        tab.removeClass('is-active');
        tab.attr('aria-hidden', 'false');
        tabNavItemControls.removeClass('is-active');
        tabNavItemControls.attr('aria-selected', 'false');
    }

    function openTab(tab, tabNavItemControls) {
        tab.addClass('is-active');
        tab.attr('aria-hidden', 'ture');
        tabNavItemControls.addClass('is-active');
        tabNavItemControls.attr('aria-selected', 'ture');
    }

    function triggerExpandImage(trigger) {

        let expanders = document.querySelectorAll('.ba-single__project .ba-expander');
        let triggerHref = trigger[0].dataset.url;

        expanders.forEach((expandersBtn) => {

            if (expandersBtn.id === triggerHref) {

                let expandersBtnPrev = expandersBtn.previousElementSibling;

                expandersBtnPrev.parentElement.parentElement.classList.add("on");

                let height = expandersBtn.previousElementSibling.querySelector(".c-image__media").offsetHeight;

                expandersBtnPrev.style.maxHeight = height+'px';
                expandersBtnPrev.parentElement.classList.add("is-expanded");
                expandersBtn.querySelector(".c-btn").classList.add("on");

                setTimeout(() => {
                    expandersBtnPrev.previousElementSibling.parentElement.scrollIntoView({});
                }, 10);
            }
        });

        if (trigger.hasClass("on")) {
            trigger.parent().prev().css('max-height', '');
            trigger.removeClass("on");
            trigger.parent().parent().removeClass("is-expanded");
            trigger.parent().prev().get(0).scrollIntoView();
        } else {
            let height = trigger.parent().prev().find(".c-image__media").outerHeight();
            trigger.parent().prev().css('max-height', height);
            trigger.addClass("on");
            trigger.parent().parent().addClass("is-expanded");
        }
    }

    /* TOC collapser */

    let collapsers = document.querySelectorAll('.ba-toc__title');

    if (collapsers) {
        collapsers.forEach((collapser) => {
            collapser.addEventListener('click', (e) => {
                collapser.classList.toggle('is-active');
            })
        });
    }

     // Function to scroll up by amount
     function scrollUpBy(amount) {
        $('.menu-outside').animate({
            scrollTop: $('.menu-outside').scrollTop() + amount
        }, 800); // 800ms duration for the scroll animation
    }

    //  Load more
    $(".lm-ba").each(function () {
        $(this).click(function () {
            console.log($('.menu-outside').scrollTop());
            $(this).closest('.lm-col').next().addClass('on');
            $(this).closest('.c-btn__wrap').addClass("frrrc");
            scrollUpBy(200);
        })
    });
}
