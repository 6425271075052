class hide_box_cta_widget {
    constructor() {
        this.cookie_name = 'hide_box_cta_widget';
        this.cookie_duration = 30; // Duration in days
        this.widget_selector = '.js-close-box-cta-widget';
        this.box_selector = '.box-cta.-site-wide';

        this.init();
    }

    init() {
        // Check cookie and hide box if set
        if (this.get_cookie(this.cookie_name)) {
            this.hide_box();
        } else {
            this.show_box();
        }

        // Bind event listener to hide box on click
        document.querySelector(this.widget_selector)?.addEventListener('click', (e) => {
            e.preventDefault();
            this.set_cookie(this.cookie_name, 'true', this.cookie_duration);
            this.hide_box();
        });
    }

    hide_box() {
        const box = document.querySelector(this.box_selector);
        if (box) {
            box.classList.add('hide-box-cta-widget');
        }
    }

    show_box() {
        const box = document.querySelector(this.box_selector);
        if (box) {
            box.classList.remove('hide-box-cta-widget');
        }
    }

    set_cookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    }

    get_cookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }
}

export default hide_box_cta_widget;