export function fakeFooterMenu() {
  let fakeMenu = document.getElementById('menu-footer-menu-js');
  if (fakeMenu) {
    fakeMenu.addEventListener('click', handleClick);
    fakeMenu.addEventListener('auxclick', handleAuxClick);
    fakeMenu.addEventListener('contextmenu', handleContextMenu);
  }
  function handleClick(event) {
    if (event.target.tagName === 'A') {
      event.preventDefault();
      var url = event.target.getAttribute('data-mask-link');
      window.location.href = url;
    }
  }
  
  function handleAuxClick(event) {
    if (event.target.tagName === 'A' && event.button === 1) {
      event.preventDefault();
      var url = event.target.getAttribute('data-mask-link');
      window.open(url, '_blank');
    }
  }
  
  function handleContextMenu(event) {
    if (event.target.tagName === 'A') {
      var url = event.target.getAttribute('data-mask-link');
      window.open(url, '_blank');
    }
  }
}