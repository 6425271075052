import { dsblsSlider } from './sliders/slider-dsbls';
import { simpleSliders } from './sliders/slider-simple';
import { advancedSliders } from './sliders/slider-advanced';
import { baSlider } from './sliders/slider-ba';
import { vSlider } from './sliders/slider-vertical';

const callSliders = () => {
    dsblsSlider();
    simpleSliders();
    advancedSliders();
    baSlider();
    vSlider();

    const sliderInd = $(".js-slider-ind");
    if(sliderInd) {
    function handleSliderInd(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {

                var sliderIndustry = new Swiper(".js-slider-ind", {
                    slidesPerView: 5,
                    spaceBetween: 0,
                    loop:false,

                    breakpoints: {
                        575: {
                            slidesPerView: 5,
                            spaceBetween: 0,
                            loop:false,
                            slideToClickedSlide: true,
                            threshold:20

                        }
                    }
                });

                observer.unobserve(entry.target);
            }
        });
    }
    // Create an intersection observer instance
    let observer = new IntersectionObserver(handleSliderInd, { threshold: 0 });

    // Select all buttons to observe

    sliderInd.each(function() {
        observer.observe(this); // Start observing each slider
    });
    }

};

export {
    callSliders,
};
