export function toggleElement() {

    jQuery("#js-toggleTrigger").click(function () {
        jQuery("#js-toggleElement ").toggleClass('open')
        jQuery(this).toggleClass('is-active')
    });

    jQuery("#js-toggleTrigger").mouseenter(function () {
        jQuery("#js-toggleElement ").addClass('open')
        jQuery(this).addClass('is-active')
    });

    jQuery("#js-toggleElement").mouseleave(function () {
        jQuery(this).removeClass('open')
        jQuery("#js-toggleTrigger").removeClass('is-active')
    });

}
