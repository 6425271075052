export function boxCtaPosition() {
  const updateBoxCtaPosition = () => {
    const tocContainer = document.querySelector("#ez-toc-container");
    const progressBar = document.querySelector("#singleProgressBar");
    const boxCta = document.querySelector(".site-footer .box-cta");

    if (boxCta && (tocContainer || progressBar)) {
      const tocHeight = tocContainer ? tocContainer.offsetHeight : 0;
      const progressHeight = progressBar ? progressBar.offsetHeight : 0;
      const totalHeight = tocHeight + progressHeight;
      boxCta.style.setProperty("--bottom-position", `${totalHeight}px`);
    }
  };

  const mutationObserver = new MutationObserver(() => {
    updateBoxCtaPosition();
  });

  const observeSizeChanges = (element) => {
    if (element) {
      const resizeObserver = new ResizeObserver(() => {
        updateBoxCtaPosition();
      });
      resizeObserver.observe(element);
    }
  };

  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });

  updateBoxCtaPosition();

  observeSizeChanges(document.querySelector("#ez-toc-container"));
  observeSizeChanges(document.querySelector("#singleProgressBar"));
}