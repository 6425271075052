/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
export default function readMore() {
    const readMoreWrappers = document.querySelectorAll('.read-more-wrapper');
    // console.log(readMoreWrappers)
    readMoreWrappers.forEach((readMoreWrapper) => {
      const readMoreBtn = readMoreWrapper.querySelector('.js-read-more-toggle');
      const readMoreBtnClick = readMoreWrapper.querySelector('.js-read-more-toggle-click');
      // const btnTextNoActive = readMoreBtn.getAttribute('data-show-less-text');
      // const btnTextActive = readMoreBtn.children[0].textContent;
      const readMoreText = readMoreWrapper.querySelector('.read-more-text');
      const isActive = readMoreWrapper.classList.contains('is-active');
      const readMoreTextHeight = (readMoreText) ? readMoreText.scrollHeight : 0;

      if (readMoreWrapper.classList.contains('is-active')){
          readMoreText.style.maxHeight = `${readMoreTextHeight}px`;
      }
  
      function showText() {
          if(readMoreText) {
            $('.read-more-wrapper').removeClass('is-active');
            $('.read-more-text').css({"maxHeight":"0"});
            readMoreWrapper.classList.add('is-active');
            // readMoreBtn.children[0].textContent = btnTextNoActive;
            readMoreText.style.maxHeight = `${readMoreTextHeight}px`;
          }
      }
  
      function hideText() {
          if(readMoreText) {
            readMoreWrapper.classList.remove('is-active');
            // readMoreBtn.children[0].textContent = btnTextActive;
            readMoreText.style.maxHeight = 0;
          }
      }
  
      function toggle() {
        if (readMoreWrapper.classList.contains('is-active')){
            readMoreText.style.maxHeight = 0;
            readMoreWrapper.classList.remove('is-active');
        }else{
          readMoreWrapper.classList.add('is-active');
          readMoreText.style.maxHeight = `${readMoreTextHeight}px`;
        }
      }
      if (readMoreBtn) {
        readMoreBtn.addEventListener('mouseenter', showText);
      }else{
        readMoreBtnClick.addEventListener('click', toggle);
      }
      
      // readMoreBtn.addEventListener("mouseleave", hideText);
    });
  
    // $('.rm-ini-drop-js').each(function(){
          
    //   $(this).mouseenter(function(){
    //       var moreTxtH = $(this).find(".read-more-text").outerHeight();
    //       var drop = $(this).find(".read-more-drop");
    //       $('.rm-ini-drop-js').removeClass('is-active');
    //       $(this).addClass('is-active');
    //       $('.read-more-drop').removeClass('is-active').height(0);
    //       drop.height(moreTxtH).addClass('is-active');
  
    //   })
    // });
  };
  
  