/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
/* eslint-disable keyword-spacing */
/**
 * Break Points Options
 */

const isBreakpointsOn = (elem, options) => {
  if(!elem) return options;

  let noColumns = parseInt(elem.getAttribute('data-slider-columns'));
  let columnsGap = parseInt(elem.getAttribute('data-slider-columns-gap'));

  if(noColumns) {
    options.slidesPerView = noColumns;
    options.breakpoints = {
      320: {
        slidesPerView: noColumns > 3 ? 1 : 1,
        spaceBetween: 10,
      },

      576: {
        slidesPerView: noColumns > 3 ? 2 : 1,
        spaceBetween: columnsGap > 20 ? 15 : 10,
      },

      1024: {
        slidesPerView: noColumns,
        spaceBetween: columnsGap > 30 ? 25 : 20,
      },
      1440: {
        slidesPerView: noColumns,
        spaceBetween: columnsGap || 20,
      },
    };
  } else {
    options.breakpoints = {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: columnsGap > 20 ? 15 : 10,
      },
      1024: {
        slidesPerView: 'auto',
        spaceBetween: columnsGap > 30 ? 25 : 20,
      },
    };
  }

  return options;
};

export {
  isBreakpointsOn,
};
