export function gForms() {


    if ($(".form-section:not(.-contact)").length) {
        $(".form-section .form-section__inner .gform_button").wrap("<span class='c-btn -primary-v3'></span>")

        var e = document.getElementById('gform_wrapper_7');
        if (e) {
            var observer = new MutationObserver(function (event) {
                $(".form-section .form-section__inner .gform_button").wrap("<span class='c-btn -primary-v3'></span>")
            })
            observer.observe(e, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
        var m = document.getElementById('gform_wrapper_10');
        if (m) {
            var observer = new MutationObserver(function (event) {
                $(".form-section .gform_button").wrap("<span class='c-btn -primary-v3'></span>")
            })
            observer.observe(m, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
    }

    if ($(".-contact").length) {

        $(".-contact .gform_button").wrap("<span class='c-btn -primary-v3 -dark'></span>")

        var e = document.getElementById('gform_wrapper_7');
        if (e) {
            var observer = new MutationObserver(function (event) {
                $(".-contact .gform_button").wrap("<span class='c-btn -primary-v3 -dark'></span>")
            })
            observer.observe(e, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
        var e2 = document.getElementById('gform_wrapper_4');
        if (e2) {
            var observer = new MutationObserver(function (event) {
                $(".-contact .gform_button").wrap("<span class='c-btn -primary-v3 -dark'></span>")
            })
            observer.observe(e2, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
    }

    if ($(".whitepaper").length) {
        $(".whitepaper .gform_button").wrap("<span class='wpcf7-submit c-btn -blue-grad -icon-spread'></span>")
        $(".whitepaper .gform_footer .c-btn").append('<span class="c-btn__ico-v2"><svg class="icon icon-btn-arrow " aria-hidden="true" role="img"><use xlink:href="#lib-icon-arrow3"></use></svg></span>')

        var e = document.getElementById('gform_wrapper_9');
        if (e) {
            var observer = new MutationObserver(function (event) {
                $(".whitepaper .gform_button").wrap("<span class='wpcf7-submit c-btn -blue-grad -icon-spread'></span>")
                $(".whitepaper .gform_footer .c-btn").append('<span class="c-btn__ico-v2"><svg class="icon icon-btn-arrow " aria-hidden="true" role="img"><use xlink:href="#lib-icon-arrow3"></use></svg></span>')

            })
            observer.observe(e, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
    }

    if ($(".newsletter-form").length) {
        $(".newsletter-form .gform_button").wrap("<span class='wpcf7-submit c-btn -blue-grad -icon-spread'></span>")
        var e = document.getElementById('gform_wrapper_6');
        if (e) {
            var observer = new MutationObserver(function (event) {
                $(".newsletter-form .gform_button").wrap("<span class='wpcf7-submit c-btn -blue-grad -icon-spread'></span>")
            })
            observer.observe(e, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
    }

    if ($(".dt-subscribe__bottom").length) {
        $(".dt-subscribe__bottom .gform_button").wrap("<span class='c-btn -primary-v3 -dark'></span>")
        var e = document.getElementById('gform_wrapper_8');
        if (e) {
            var observer = new MutationObserver(function (event) {
                $(".dt-subscribe__bottom .gform_button").wrap("<span class='c-btn -primary-v3 -dark'></span>")
            })
            observer.observe(e, {
                attributes: true,
                attributeFilter: ['class'],
                childList: true,
                characterData: false
            })
        }
    }

    // if ($(".single-post__content .form-section").length) {
    //     $(".single-post__content .form-section  .gform_button").wrap("<span class='wpcf7-submit c-btn -opacity'></span>");

    //     var e2 = document.getElementById('gform_wrapper_10');
    //     if(e2){
    //         var observer = new MutationObserver(function (event) {
    //             $(".single-post__content .form-section  .gform_button").wrap("<span class='wpcf7-submit c-btn -opacity'></span>");
    //         })
    //         observer.observe(e, {
    //             attributes: true, 
    //             attributeFilter: ['class'],
    //             childList: true, 
    //             characterData: false
    //         })
    //     } 
    // }

}
