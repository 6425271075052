import {u_slideDown, u_slideUp} from "../utils/u_slide-up-down";

const toggleSubmenu = () => {

    let selector = document.querySelector('.footer-nav');

    let items;
    if (selector) {
        items = selector.querySelectorAll('.footer-nav .menu-item-has-children');
    }

    if(items) {

        items.forEach((btn, i) => {

            let submenu = btn.querySelector('.sub-menu');
            btn.setAttribute('aria-expanded', 'false');
            u_slideUp(submenu);


            btn.firstElementChild.addEventListener('click', (ev) => {
                ev.preventDefault();
                if(ev.target.tagName.toLowerCase() === 'a') {
                    //return;
                }

                if(btn.classList.contains('is-toggled')) {
                    u_slideUp(submenu);
                    btn.classList.remove('is-toggled');
                    btn.setAttribute('aria-expanded', 'false');
                }
                else {
                    items.forEach( (item , j) => {
                        let submenu = item.querySelector('.sub-menu');
                        if(item.classList.contains('is-toggled')) {
                            u_slideUp(submenu);
                            item.classList.remove('is-toggled');
                            item.setAttribute('aria-expanded', 'false');
                        }
                    });
                    btn.classList.add('is-toggled');
                    btn.setAttribute('aria-expanded', 'true');
                    u_slideDown(submenu);
                }
            })
        })
    }
}

export {
    toggleSubmenu
}
