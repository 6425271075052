/**
 * Slide to Clicked Slide Slider Options
 */

const isSlideToClicedSlideOn = (elem, options) => {
    if(!elem) return options;

    let slideToClicedSlide = elem.getAttribute('data-slider-slide-to-clicked-slide');
    
    if(slideToClicedSlide === 'true'){
        options.slideToClicedSlide = true;
    }

    return options;
}


export {
    isSlideToClicedSlideOn
}