export function backToTop(el) {
    const btn = document.getElementById(el);

    if(btn) {
        btn.addEventListener('click', () => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        })
    }
}
