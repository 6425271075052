export function pauseHeroAnimations() {

    const elementsToObserve = document.querySelectorAll('.home-hero .m-banner__content , .home-hero .-client-slides');
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Element is in the viewport, remove the 'paused' class
        entry.target.classList.remove('paused');
      } else {
        // Element is not in the viewport, add the 'paused' class
        entry.target.classList.add('paused');
      }
    });
  }, {
    threshold: 0
  });

  elementsToObserve.forEach(element => observer.observe(element));
}