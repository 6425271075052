/* eslint-disable linebreak-style */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */

// const $videoCont = jQuery('.js-hero-video-cont');

export default function heroPlayer(selector) {
  const vTags = document.querySelectorAll(selector);

  function videoRWD() {
    vTags.forEach((vTag) => {
      if (jQuery(vTag).is(':hidden')) {
        vTag.autoplay = !vTag.autoplay;
      }
    });
  }

  /* Custom Video Controls */
  function videoActions() {
    vTags.forEach((vTag) => {
      const $videoCont = jQuery(vTag).parents('.js-video-section').find('.js-hero-video-cont');
      // vanilla js cont
      const videoCont = $videoCont.get(0);

      const playAction = $videoCont.find('.btn-play').get(0);
      const muteAction = $videoCont.find('.btn-mute').get(0);
      const controlAction = $videoCont.find('.btn-control').get(0);
      vTag.controls = false;

      vTag.addEventListener('ended', function () {
        this.pause();
        // vTag.parentElement.classList.add('is-ended');
        document.querySelector('.video-hidden').classList.remove('is-enabled');
        //   document.querySelector('.banner-top').classList.remove('is-disabled');
        vTag.currentTime = 0;
        playAction.classList.add('is-pause');
        playAction.classList.remove('is-playing');
      }, false);

      // pause video beore end  and add contact link
      vTag.addEventListener('timeupdate', function () {
        if (this.currentTime >= 61) {
          this.pause();
        }
        if (this.currentTime >= 58) {
          videoCont.querySelector('.invideo-link').classList.add('show');
        } else {
          videoCont.querySelector('.invideo-link').classList.remove('show');
        }
      }, false);

      function togglePlay() {
        if (vTag.paused || vTag.ended) {
          playAction.classList.add('is-playing');
          playAction.classList.remove('is-pause');
          vTag.parentElement.classList.remove('is-ended');
          vTag.play();
        } else {
          playAction.classList.add('is-pause');
          playAction.classList.remove('is-playing');
          vTag.pause();
        }
      }

      function toggleMute() {
        vTag.muted = !vTag.muted;
        if (vTag.muted) {
          muteAction.classList.add('is-muted');
          muteAction.classList.remove('is-sound');
        } else {
          muteAction.classList.add('is-sound');
          muteAction.classList.remove('is-muted');
        }
      }
      function toggleControl() {
        if (controlAction) {
          vTag.controls = !vTag.controls;
          if (vTag.controls) {
            controlAction.classList.add('is-muted');
            controlAction.classList.remove('is-sound');
          } else {
            controlAction.classList.add('is-sound');
            controlAction.classList.remove('is-muted');
          }
        }
      }

      playAction.addEventListener('click', () => {
        togglePlay();
      });
      muteAction.addEventListener('click', () => {
        toggleMute();
      });
      if (controlAction) {
        controlAction.addEventListener('click', () => {
          toggleControl();
        });
      }
    });
  }

  // Play button on the services pages

  function playVideo(el) {
    if (document.querySelectorAll(el).length > 0) {
      const playBtns = document.querySelectorAll(el);

      playBtns.forEach((playBtn) => {
        playBtn.addEventListener('click', (e) => {
          console.log("click");
          e.stopPropagation();
          const $videoCont = jQuery(e.target).parents('.js-video-section').find('.js-hero-video-cont');

          $videoCont.addClass('is-enabled');
          document.body.classList.add('hide-scrollbar');
          $videoCont.animate({
            opacity: 1,
          }, 500);

          $videoCont.find('.videoPlayer .js-source1').prop('src', jQuery(playBtn).data('video-url'));
          $videoCont.find('.videoPlayer .js-source2').prop('src', jQuery(playBtn).data('video-url'));

          const vTag = $videoCont.find('.videoPlayer').get(0);
          
          vTag.load();
          vTag.play();
        });
      });
    }
  }

  function stopVideo(el) {
    if (document.querySelectorAll(el).length > 0) {
      const closeBtns = document.querySelectorAll(el);

      closeBtns.forEach((closeBtn) => {
        closeBtn.addEventListener('click', (e) => {
          e.stopPropagation();
          const $videoCont = jQuery(e.target).parents('.js-video-section').find('.js-hero-video-cont');

          $videoCont.removeClass('is-enabled');
          document.body.classList.remove('hide-scrollbar');

          $videoCont.animate({
            opacity: 0,
          }, 500);

          const vTag = $videoCont.find('.videoPlayer').get(0);

          vTag.pause();
          vTag.currentTime = 0;
        });
      });
    }
  }

  if (vTags.length > 0) {
    videoRWD();
    videoActions();

    playVideo('.js-play-video');
    stopVideo('.js-stop-video');
  }
}
