export function priceCalculator() {
    // Check if .calc-form exists
    var calcForm = document.querySelector('.calc-form');

    if (calcForm) {

        function legends(){
            var radioLegends = document.querySelectorAll('.gfield--type-radio legend');

            radioLegends.forEach(function (legend) {
                // Create a new div element
                var wrapperDiv = document.createElement('div');
                 // Add the class "legend-wrap" to the wrapper div
                wrapperDiv.classList.add('legend-wrap');

                // Wrap the legend element with the new div
                legend.parentNode.insertBefore(wrapperDiv, legend);
                wrapperDiv.appendChild(legend);
            });
        }


        jQuery(document).on('gform_post_render', function(event, form_id, current_page){
            // if current page is 2 activate page 1
            if (current_page == 2) {
                legends();
                setTimeout(() => {
                    $(".gform_page[id$=_1]").addClass('on');
                }, 1000);
                setTimeout(() => {
                    $(".gform_page[id$=_1]").addClass('on');
                }, 100);
                // if current page is 1 activate page 2
            }else if(current_page == 1){
                legends();
                setTimeout(() => {
                    $(".gform_page[id$=_2]").addClass('on');
                }, 600);
                setTimeout(() => {
                    $(".gform_page[id$=_2]").addClass('on');
                }, 100);
            }
     
        });

        // data layers for google
        const formWrap = document.querySelector('.calc-form_wrapper .gform_page ');
        const secPage = formWrap.parentElement.children[1];
        // const lastPage = formWrap.parentElement.children[2];


        // Get the next button of the second page
        const buttonNext = secPage.querySelector(".gform_next_button");

        // Get the submit button
        // const buttonSubmit = lastPage.querySelector(".gform_next_button");

        // Get the first page input (IDs differs per domain - check LIVE)
        const wTypeId = document.getElementById('input_12_15');
        const nPages = document.getElementById('input_12_16');
        const hRate = document.getElementById('input_12_10');

        // Get the Second page radio fieldsets (ID differs per domain - check LIVE)
        const fieldsetContainer = secPage;
        const fieldsets = fieldsetContainer.querySelectorAll('fieldset');

        // Object to store changes for each fieldset
        const fieldsetChanges = {};

        // Function to get values and handle changes for a given fieldset
        function handleFieldsetChanges(fieldsetId) {
            const fieldset = document.getElementById(fieldsetId);
            fieldset.querySelectorAll('input').forEach(input => {

                // check the radio default choice and set the output for data layer
                const checkedRadio = fieldset.querySelector('input[type="radio"]:checked');
                const label = fieldset.querySelector(`label[for="${checkedRadio.id}"]`);
                const labelText = label.textContent.trim();
                fieldsetChanges[fieldsetId] = labelText; 
                
                // on changes update the output for the data layer
                input.addEventListener('change', function(event) {
                    const inputId = event.target.id;
                    const label = document.querySelector(`label[for="${inputId}"]`);
                    const fieldsetChange = label.textContent.trim();
                    fieldsetChanges[fieldsetId] = fieldsetChange; // Store the change for this fieldset
                    console.log(`Change in fieldset ${fieldsetId}: ${fieldsetChange}`); 
                });
            });
        }

        // Iterate over each fieldset to collect checked labels and set up event listeners
        fieldsets.forEach(fieldset => {
            const fieldsetId = fieldset.id;
            handleFieldsetChanges(fieldsetId);
        });

        var stepN = "";

        function getValues() {
            // get values for first page
            const wTypeSelection = wTypeId.options[wTypeId.selectedIndex].innerText;
            const nPagesVal = nPages.value;
            const hRateVal  = hRate.value;

            // handle data dynamically for second page
            const eventData = {};
            fieldsets.forEach(fieldset => {
                const fieldsetId = fieldset.id;
                const legendText = fieldset.querySelector('legend').textContent.trim();
                const formattedLegend = legendText.replace(/[^a-zA-Z0-9]+(.|$)/g, (match, chr) => {
                    return chr ? chr.toUpperCase() : '';
                }).replace(/^./, (match) => match.toLowerCase());
                eventData[formattedLegend] = fieldsetChanges[fieldsetId] || 'No radio button checked';
            });
            // console.log({...eventData});
            // console.log(wTypeSelection);
            // console.log(nPagesVal);
            // console.log(hRateVal);
            // console.log(stepN);
            // Pushing variables to dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': stepN,
                'websiteType': wTypeSelection, // Replace with actual data
                'landingPages': nPagesVal, // Replace with actual data
                'hourlyRate': hRateVal, // Replace with actual data
                ...eventData
            });
        }

        if (buttonNext) {
            buttonNext.addEventListener('click', function () {
                stepN = "website_calculator_form_step1";
                getValues();
            });
        }

        jQuery(document).on('gform_confirmation_loaded', function(event, formId){
            
            if((formId == 12) || (formId == 16)) {
                stepN = "website_calculator_form_finish";
                getValues();
            }
        });


        const customSpinners = calcForm.querySelectorAll('.calc-custom-spin');

        customSpinners.forEach((spinner) => {
            const elem = spinner.querySelector('.ginput_container_number');
            const field = spinner.querySelector('input');

            const upArrow = document.createElement('span');
            upArrow.className = 'spin-arrows up';
            elem.prepend(upArrow);

            const downArrow = document.createElement('span');
            downArrow.className = 'spin-arrows down';
            elem.append(downArrow);

            downArrow.addEventListener('click', () => {
                const currentVal = parseCurrencyToCents(field.value);
                const newVal = currentVal - 500;
                const formatVal = formatCentsAsCurrency(newVal);
                if (newVal > field.min * 100) {
                    field.value = formatVal;
                } else {
                    field.value = formatCentsAsCurrency(field.min * 100);
                }

                const event = new Event('change');
                field.dispatchEvent(event);
            });

            upArrow.addEventListener('click', () => {
                const currentVal = parseCurrencyToCents(field.value);
                const newVal = currentVal + 500;
                const formatVal = formatCentsAsCurrency(newVal);
                if (newVal < field.max * 100) {
                    field.value = formatVal;
                } else {
                    field.value = formatCentsAsCurrency(field.max * 100);
                }
                const event = new Event('change');
                field.dispatchEvent(event);
            });

            field.addEventListener('change', () => {
                const newVal = parseCurrencyToCents(field.value);
                if (newVal > field.max * 100) {
                    field.value = formatCentsAsCurrency(field.max * 100);
                    const event = new Event('change');
                    field.dispatchEvent(event);
                }

                if (newVal < field.min * 100) {
                    field.value = formatCentsAsCurrency(field.min * 100);
                    const event = new Event('change');
                    field.dispatchEvent(event);
                }

            });
        });


        const parseCurrencyToCents = (currencyStr) => {
            // Remove currency symbols and whitespace
            let cleanStr = currencyStr.replace(/[$\s]/g, '');

            // Determine which format is being used
            if (cleanStr.includes(',') && cleanStr.includes('.')) {
                // If both separators exist, check which comes first
                const commaPos = cleanStr.indexOf(',');
                const dotPos = cleanStr.indexOf('.');

                if (commaPos < dotPos) {
                    // Format: 1,234.56
                    cleanStr = cleanStr.replace(/,/g, '');
                } else {
                    // Format: 1.234,56
                    cleanStr = cleanStr.replace(/\./g, '').replace(',', '.');
                }
            } else if (cleanStr.includes(',')) {
                // Just comma - could be decimal or thousands
                if (cleanStr.lastIndexOf(',') > cleanStr.length - 4) {
                    // Comma is decimal separator
                    cleanStr = cleanStr.replace(',', '.');
                } else {
                    // Comma is thousands separator
                    cleanStr = cleanStr.replace(/,/g, '');
                }
            }

            // Convert to float and then to cents
            return Math.round(parseFloat(cleanStr) * 100);
        };


        const formatCentsAsCurrency = (cents) => {
            return `$${(cents / 100).toFixed(2)}`;
        };

    }
}