export function autoTabs() {

    if($(".hero .projects").length){

        setInterval(function(){
            var active_autoTab = $('.js-auto-loop-tabs.is-active');
            var first_autoTab = $('.js-auto-loop-tabs.first');

            if( active_autoTab.hasClass("last") )
                first_autoTab.click();
            else
                active_autoTab.next().click();
        }, 4000);
    }
}
