export default function readMoreX() {

    // Code relevant to the industry-services.php module
    const tabHeads = document.querySelectorAll('.js-tab-head');

    tabHeads.forEach(tabHead => {
        tabHead.addEventListener('click', () => {
            activateTab(tabHead);
        });

        tabHead.addEventListener('keydown', event => {
            if (event.key === 'Enter') {
                activateTab(tabHead);
            }
        });
    });

    function activateTab(tabHead) {
        tabHeads.forEach(otherTabHead => {
            otherTabHead.classList.remove('is-active');
            const content = otherTabHead.querySelector('.c-block__text-wrap');
            if (content) {
                content.classList.add('-hidden');
            }
        });

        tabHead.classList.add('is-active');
        const activeContent = tabHead.querySelector('.c-block__text-wrap'); 
        if (activeContent) {
            activeContent.classList.remove('-hidden');
        }
    }

    // Code relevant to the wrapper-content.php module
    const buttons = document.querySelectorAll('.full-block .c-btn');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            const fullBlock = this.closest('.full-block');
            const collapsable = fullBlock.querySelector('.collapsable.read-more-text');

            // Toggle the -hidden class on the collapsable element
            collapsable.classList.toggle('-hidden');

            // Toggle the text of the button
            const buttonText = this.querySelector('.-text');
            buttonText.textContent = collapsable.classList.contains('-hidden') ? 'Click To Read Full Article' : 'Hide Article';

            // Toggle the -active class on the button
            this.classList.toggle('-active');

            // Scroll into view if the collapsable element is hidden
            if (collapsable.classList.contains('-hidden')) {
                fullBlock.scrollIntoView({behavior: 'instant', block: 'start'});
            }
        });
    });

};