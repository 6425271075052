/**
 * Before/after SLIDER type
 */

const baSlider = () => {

    const baSliderEl = $('.js-before-after');

    if(baSliderEl) {
        function handleBaSliderIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {

                    let baSliderElCount = document.querySelectorAll(".s-before-after .c-slider-nav__thumb").length;
                    let startSlide = Math.round((baSliderElCount / 2 - 1));
                    

                    let baThumbs = new Swiper('.ba-thumbs', {
                        spaceBetween: 10,
                        slidesPerView: 3,
                        freeMode: true,
                        watchSlidesProgress: true,
                        centeredSlides: true,
                        slideToClickedSlide:true,
                        allowTouchMove: true,
                        threshold:30,
                        breakpoints: {
                            768: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                                freeMode: true,
                                watchSlidesProgress: true,
                                centeredSlides: false,
                                slideToClickedSlide:true,
                                allowTouchMove: false,
                                threshold:30,
                            },
                            
                        },
                    });
                    let baSlider = new Swiper('.ba-slider', { 
                        autoHeight: true,
                        autoplay:false,
                        navigation: {
                            nextEl: '.ba-slider-next',
                            prevEl: '.ba-slider-prev',
                        },
                        thumbs: {
                            swiper: baThumbs,
                        },
                    
                        // on: {
                        //     slideChange: function () {
                        //         // let currentIndex = baSlider.activeIndex;
                        //         // let currentSlide = $(".js-process-thumbs .swiper-slide")[currentIndex];
                        //         // let slide = currentSlide.querySelector('.l-testimonials');
                        //         // console.log($(currentSlide).height() );
                        //         // // $('.ba-slider.swiper-container').height($(currentSlide).height() )
                        //         baSlider.updateAutoHeight(2000)
                        //     },
                        // },
                        
                    });

                    // baSlider.controller.control = baThumbs;
                    // baThumbs.controller.control = baSlider;
                    setTimeout(() => {
                        baSlider.slideTo(startSlide);
                    }, 1000);
                    

                    let csThumbs = new Swiper('.cs-thumbs', {
                        spaceBetween: 10,
                        slidesPerView: 3,
                        freeMode: true,
                        watchSlidesProgress: true,
                        centeredSlides: true,
                        slideToClickedSlide:true,
                        breakpoints: {
                            768: {
                                slidesPerView: 5,
                                spaceBetween: 10,
                                freeMode: true,
                                watchSlidesProgress: true,
                                centeredSlides: false,
                                slideToClickedSlide:true,
                            },
                            
                        },
                    });

                    let csSlider = new Swiper('.cs-slider', {
                        navigation: {
                            nextEl: '.cs-slider-next',
                            prevEl: '.cs-slider-prev',
                        },
                        thumbs: {
                            swiper: csThumbs,
                        },
                    });
                    observer.unobserve(entry.target);
                }
            });
        }
        // Create an intersection observer instance
        var observer = new IntersectionObserver(handleBaSliderIntersection, { threshold: 0 });

        // Select all buttons to observe

        baSliderEl.each(function() {
            observer.observe(this); // Start observing each slider
        });

        // csSlider.controller.control = csThumbs;
        // csThumbs.controller.control = csSlider;

    }

    var processSlider = $('.s-our-process');
    if(processSlider){

        function handlePrSliderIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
    

                let prThumbs = new Swiper('.js-process-thumbs ', {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    // centeredSlides: true,
                    // loop: true,
                    threshold: 20,
                    slideToClickedSlide: true,
                    // initialSlide: startSlide,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: '.pr-next',
                        prevEl: '.pr-prev',
                    },
                    breakpoints: {
                        768: {
                        slidesPerView: "auto",
                        allowTouchMove: true,
                        threshold: 100
                        //   centeredSlides: true,
                        },
                        
                    },
                
                });
                prThumbs.on('slideChange', function () {
                        
                });
                let prSlider = new Swiper('.js-process-slides', {
                    slidesPerView: 1,
                    // centeredSlides: true,
                    // loop: true,
                    // loopedSlides: baSliderElCount,
                    // initialSlide: startSlide,
                    autoHeight: true,
                    navigation: {
                        nextEl: '.pr-next',
                        prevEl: '.pr-prev',
                    }, 
                    thumbs: {
                        swiper: prThumbs,
                    },
                    on: {
                        slideChange: function () {
                            
                            const jumper = document.querySelector(".jumper");

                            let currentSlide = prSlider.activeIndex;
                            let thumbSlide = $(".js-process-thumbs .swiper-slide")[currentSlide];
                            let offsetLeft = thumbSlide.offsetLeft;
                            let aiWidth = thumbSlide.offsetWidth;
                            jumper.style.left = `${( offsetLeft + (aiWidth / 2)) - 45 }px`;
                            jumper.classList.add("yoohoo");
                            setTimeout(() => {
                                jumper.classList.remove("yoohoo");
                            }, 450);
                            },
                    },
                });
                const navParents = document.querySelectorAll(".nav-jumper");

                navParents.forEach(function (navParent, index) {
                    const jumper = navParent.querySelector(".jumper");
                    const items = navParent.querySelectorAll(".l-slider-nav .swiper-slide");
                    const activeItem = navParent.querySelector(".l-slider-nav .swiper-slide-thumb-active");

                    let offsetLeft = activeItem.offsetLeft;
                    let aiWidth = activeItem.offsetWidth;
                    jumper.style.left = `${( offsetLeft + (aiWidth / 2)) - 45 }px`;
                
                
                });
                observer.unobserve(entry.target);
            }
        });
        }
        // Create an intersection observer instance
        var observer = new IntersectionObserver(handlePrSliderIntersection, { threshold: 0 });
        
        // Select all buttons to observe
        
        processSlider.each(function() {
            observer.observe(this); // Start observing each slider
        });
    }

    
    $(".js-tabs-nav-item").click(function () {
        $(".gridder-show").remove();
    })
}


export {
    baSlider
}
