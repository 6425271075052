export function locationAccordion() {

    if(document.querySelector(".js-location") != null) {
        const accordionItem = document.querySelectorAll(".js-location-item");
        accordionItem.forEach((item,index)=>{
          item.addEventListener("click", function() {
            item.classList.toggle("active");
            
            let description = item.querySelector(".js-location-item-content");
            if(item.classList.contains("active")){
              description.style.height="auto";
              setTimeout(() => {
                description.classList.add('active');
                description.style.opacity = "1";
              }, 400);
    
            }else{
                description.style.height = "0px";
                description.style.opacity = "0";
                description.classList.remove('active');
              }
              removeOpenedContent(index);
            })
          })
          
          function removeOpenedContent(index){
            accordionItem.forEach((item2,index2)=>{
              if(index != index2){
                item2.classList.remove("active");
                let descrip = item2.querySelector(".js-location-item-content");
                descrip.style.height="0px";
                descrip.style.opacity = "0";
                descrip.classList.remove('active');
            }
        })
        }
    }
}
